import styled from 'styled-components'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { EmployeeSelect } from '../EmployeeSelect'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & select {
    padding: 10px;
  }
`

const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 12px;
`

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ededf0;
`

const Button = styled.button`
  background-color: #006f51;
  margin-top: 20px;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 0.5em;
`

const Error = styled.label`
  color: red;
  margin-top: 8px;
  align-self: center;
`

const api = axios.create({ baseURL: '/api' })

interface CreateResumeFormProps {
  onCreate: (resume: any) => Promise<void>
}

const CreateResumeForm = ({ onCreate }: CreateResumeFormProps) => {
  const [employees, setEmployees] = useState<any[]>([])
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('')
  const [purpose, setPurpose] = useState('')
  const [expiration, setExpiration] = useState('')
  const [error, setError] = useState<string>('')

  const getEmployees = async () => {
    try {
      const { data: employees } = await api.get('/employees')
      setEmployees(employees)
    } catch (error) {
      console.error('Error fetching employee names:', error)
    }
  }

  useEffect(() => {
    getEmployees()
  }, [])

  const handleSubmit = async (e: any) => {
    try {
      const created = await api.post('/resumes', {
        employeeId: selectedEmployeeId,
        purpose,
        expiresAt: expiration,
      })
      onCreate(created.data)
    } catch (e: any) {
      setError(e.response.data?.error ?? 'Unknown error, please check network logs')
    }
  }

  return (
    <Container>
      <h3>Create new resume link</h3>
      <Label>Employee:</Label>
      <EmployeeSelect
        selectedEmployeeId={selectedEmployeeId}
        changedEmployee={async (employeeId: string) => {
          setSelectedEmployeeId(employeeId)
        }}
        employees={employees}
      />
      <Label>Purpose:</Label>
      <Input type="text" id="purpose" name="purpose" onChange={(e) => setPurpose(e.target.value)} />

      <Label>Expires At*:</Label>
      <Input type="date" id="expiresAt" name="expiresAt" onChange={(e) => setExpiration(e.target.value)} required />

      <Button type="submit" onClick={handleSubmit}>
        Add
      </Button>
      <Error>{error}</Error>
    </Container>
  )
}

export default CreateResumeForm
