import React from 'react'
import Footer from '../../components/Footer'
import HeaderWithLogo from '../../components/Header/HeaderWithLogo'
import { Message } from '../../styles'

const Home = ({ message }: { message?: string }) => {
  return (
    <>
      <HeaderWithLogo />
      <Message>{message}</Message>
      <Footer />
    </>
  )
}

export default Home
