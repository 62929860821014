import styled from 'styled-components'
import { ItemSeparator, Container } from '../../styles'

export const EducationContainer = styled(Container)`
  padding-bottom: 108px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 800px) {
    justify-content: space-between;
    flex-direction: row;
  }
`

export const ItemDate = styled.div`
  color: ${(props) => props.theme.colors.gray50};
  font-size: 16px;
  ${(props) => props.theme.fonts.inter400};
  border-radius: 79px;
  border: 1px solid ${(props) => props.theme.colors.gray25};
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 16px;
  font-weight: 400;
`

export const ItemStatus = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 16px;
  font-size: 16px;
  border-radius: 79px;
  ${(props) => props.theme.fonts.inter400};
  background-color: ${(props) => props.theme.colors.gray10};
  margin-left: 12px;
  font-weight: 600;
  text-transform: capitalize;
`

export const EducationSeparator = styled(ItemSeparator)`
  margin: 32px 0;
`
