import React, { useState, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { FilterForm, Label, Input, Select } from './styles.js'

interface FilterLinksFormProps {
  filter: (firstName: string, lastName: string, hasExpired: string | undefined) => Promise<void>
}

const FilterLinksForm = ({ filter }: FilterLinksFormProps) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [hasExpired, setHasExpired] = useState<string>()

  const debouncedFilter = useCallback(
    debounce(
      (firstNameSearch, lastNameSearch, hasExpired) => {
        filter(firstNameSearch, lastNameSearch, hasExpired)
      },
      1000,
      { leading: true },
    ),
    [],
  )

  const updateFirstName = (newFirstName: string) => {
    debouncedFilter(newFirstName, lastName, hasExpired)
    setFirstName(newFirstName)
  }

  const updateLastName = (newLastName: string) => {
    debouncedFilter(firstName, newLastName, hasExpired)
    setLastName(newLastName)
  }
  const updateExpired = (newHasExpired: string) => {
    debouncedFilter(firstName, lastName, newHasExpired)
    setHasExpired(newHasExpired)
  }

  return (
    <FilterForm>
      Filter
      <Label htmlFor="firstName">
        FirstName:
        <Input id="firstName" onChange={(e) => updateFirstName(e.target.value)} />
      </Label>
      <Label htmlFor="lastName">
        LastName:
        <Input id="lastName" onChange={(e) => updateLastName(e.target.value)} />
      </Label>
      <Select onChange={(e) => updateExpired(e.target.value)}>
        <option value="">&lt;filter by expiration state&gt;</option>
        <option value="true">expired</option>
        <option value="false">non expired</option>
      </Select>
    </FilterForm>
  )
}

export default FilterLinksForm
