import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../components/Header'
import Summary from '../../components/Summary'
import Experience from '../../components/Experience'
import Education from '../../components/Education'
import Footer from '../../components/Footer'
import { Employee } from '../../entities/employee'
import axios from 'axios'
import Home from '../Home'
import TechnicalSkillsContainer from '../../components/TechnicalSkills'

const api = axios.create({
  baseURL: '/api',
})

const Resume = () => {
  const { key } = useParams()

  const [loading, setLoading] = useState<Boolean>(true)
  const [error, setError] = useState<string>('')
  const [employee, setEmployee] = useState<Employee>()
  const [resume, setResume] = useState<any>()
  const [projects, setProjects] = useState<any[]>([])
  const [education, setEducation] = useState<any[]>([])

  const fetchData = async () => {
    try {
      const { data: resumeData } = await api.get(`/resumes/${key}`)

      setEmployee(resumeData.employee)
      setResume(resumeData.resume)
      setProjects(resumeData.projects)
      setEducation(resumeData.education)
      setLoading(false)

      if (resumeData.employee) {
        document.title = `${resumeData.employee.firstName} ${resumeData.employee.lastName} at SCVSoft`
      } else {
        document.title = `${document.title} - Resume Not Found`
      }
    } catch (e: any) {
      document.title = `${document.title} - Resume Not Found`
      setError(e.response.data?.message ?? 'Unknown error, please check network logs')
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  if (loading) {
    return <Home message="Loading" />
  }

  if (error) {
    return <Home message={error} />
  }

  if (!employee) {
    return <Home message="Not found" />
  }

  return (
    <>
      <Header employee={employee} />
      <Summary resume={resume} />
      <Experience projects={projects} />
      <TechnicalSkillsContainer items={resume} />
      <Education items={education} />
      <Footer />
    </>
  )
}

export default Resume
