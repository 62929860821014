import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const theme = {
  body: {
    marginLeft: '17%',
    marginRight: '17%',
  },
  colors: {
    black: '#000000',
    gray10: '#EEF4FA',
    gray25: '#C6CFD5',
    gray50: '#66687B',
    gray75: '#373A50',
    gray100: '#20233A',
    tertiary25: '#FFE325', // yellow
    tertiary100: '#302102', // black
    primary25: '#9BF4E4', // green
    primary50: '#01A67D', // green
    primary75: '#006F51',
    secondary10: '#F8F0FF', // purple
    secondary25: '#D6C1F4', // purple
    secondary50: '#804BD7', // purple
    white: '#ffffff',
  },
  fonts: {
    inter400: `
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
    `,
    inter700: `
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
    `,
    manrope800: `
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 800;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
    `,
  },
}

export const Container = styled.div`
  padding: 0 24px;
  @media screen and (min-width: 800px) {
    margin-left: ${(props) => props.theme.body.marginLeft};
    margin-right: ${(props) => props.theme.body.marginRight};
    padding: 0;
  }
`

export const SectionTitle = styled.div`
  margin-bottom: 32px;
  ${(props) => props.theme.fonts.manrope800}
  font-size: 36px;
  line-height: 48px;
  color: ${theme.colors.gray100};
`
export const LogoSCV = styled.img`
  width: 22px;
  height: 22px;
  margin-bottom: -5px;
  margin-right: 3px;
`

export const ItemSCVContainer = styled.div`
  color: ${theme.colors.primary50};
`

export const ItemTitle = styled.div`
  margin: 12px 0;
  ${theme.fonts.manrope800}
  font-size: 22px;
  line-height: 38px;
  color: ${theme.colors.black};
  @media screen and (min-width: 800px) {
    margin: 0;
  }
`

export const ItemSubtitle = styled.div`
  margin-bottom: 16px;
  ${theme.fonts.manrope800}
  font-size: 22px;
  line-height: 30px;
  color: ${theme.colors.primary75};
`

export const ItemDescription = styled.div`
  ${theme.fonts.inter400}
  font-size: 18px;
  line-height: 32px;
  color: ${theme.colors.gray100};
`

export const Separator = styled.div`
  width: 57%;
  margin: auto;
  height: 0px;
  border: 2px solid #c6cfd5;
  flex: none;
  order: 2;
  flex-grow: 0;
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`
export const Message = styled.div`
  margin-left: ${(props) => props.theme.body.marginLeft};
  margin-bottom: 16px;
  ${(props) => props.theme.fonts.manrope800}
  font-size: 48px;
  line-height: 48px;
  margin-top: 120px;
  margin-bottom: 160px;
`

export const ItemSeparator = styled.div`
  height: 2px;
  background-color: ${theme.colors.gray10};
  width: 100%;
  margin: 80px 0;
`

export const App = styled.div``
