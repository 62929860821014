import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import TabList, { TabContainer, TabItem, TabItemSelected } from './TabSelector'
import ResumesTable from './ResumesTable'
import CreateResumeForm from './CreateResumeForm'
import FilterLinksForm from './FilterLinksForm'
import { Message } from '../../styles'
import axios from 'axios'
import { Navigate, Link } from 'react-router-dom'
import { ReactComponent as SCVLogo } from '../../assets/scvLogo.svg'
import './base.css'

const api = axios.create()

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
  font-family: Inter, sans-serif;
`

const TopBar = styled.nav`
  font-family: Manrope, sans-serif;
  display: flex;
  background-color: #2d284f;
  color: #fafafa;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0em 2em;
  width: 100%;
  box-sizing: border-box;
  & header {
    display: flex;
    align-items: center;
    gap: 0.8em;
  }
  & h2 {
    font-weight: 600;
  }
`

const Logout = styled.button`
  background: transparent;
  color: #fafafa;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  cursor: pointer;
`

const MainContainer = styled.main`
  padding: 1em 2em;
  flex: 1;
`
const Sidebar = styled.aside`
  background-color: #f9f9f9;
  flex: 0.3;
  padding: 2em;
  height: calc(100vh - 50px);
`

const Button = styled.button`
  background-color: #f33;
  padding: 10px;
  width: 100%;
  color: #fff;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
`

const Admin = () => {
  const [loading, setLoading] = useState<Boolean>(true)
  const [logged, setLogged] = useState<Boolean>(false)
  const [resumes, setResumes] = useState<any[]>([])

  const fetchResumesData = async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/api/resumes')
      setLogged(true)
      setResumes(data)
    } catch (e: any) {
      if (e.response.data?.status === 401) {
        setLogged(false)
      }
    } finally {
      setLoading(false)
    }
  }

  const filterResumesData = async (firstName: string, lastName: string, hasExpired: string | undefined) => {
    try {
      const { data } = await api.get(
        `/api/resumes?firstName=${firstName}&lastName=${lastName}&hasExpired=${hasExpired}`,
      )
      setResumes(data)
    } catch (e: any) {
      if (e.response.data?.status === 401) {
        setLogged(false)
      }
    }
  }

  const handleCreation = async (resume: any) => {
    setResumes([resume].concat(resumes))
  }

  const handleDelete = async (resume: any) => {
    await api.delete('/api/resumes/' + resume.hash)
    const newResumes = resumes.filter((r) => r.hash !== resume.hash)
    setResumes(newResumes)
  }

  const deleteAllExpired = async () => {
    if (window.confirm('Are you sure you want to delete all expired links?')) {
      const reponseDelete = await api.delete('/api/resumes/expired')
      const idsDeleted = reponseDelete.data.map((deletedData: any) => deletedData.id)
      const newResumes = resumes.filter((r) => !idsDeleted.includes(r.id))
      setResumes(newResumes)
    }
  }

  const handleLogout = async (e: any) => {
    e.preventDefault()
    await api.post('/api/logout')
    setLogged(false)
  }

  useEffect(() => {
    fetchResumesData()
  }, [])

  if (loading) {
    return <Message>Loading...</Message>
  }

  if (!logged) {
    return <Navigate to="/login" replace={true} />
  }

  return (
    <>
      <TopBar>
        <header>
          <SCVLogo />
          <h2 style={{ margin: 0 }}>Resume builder</h2>
        </header>
        <Logout onClick={handleLogout}>Logout</Logout>
      </TopBar>
      <TabContainer>
        <TabList>
          <TabItemSelected>Shareable Links</TabItemSelected>
          <TabItem>
            <Link to="/admin/preview">Preview</Link>
          </TabItem>
        </TabList>
      </TabContainer>
      <Container>
        <MainContainer>
          <Toaster />
          <FilterLinksForm filter={filterResumesData} />
          <ResumesTable resumes={resumes} onDelete={handleDelete} />
        </MainContainer>
        <Sidebar>
          <Button onClick={deleteAllExpired}>🗑️ Delete all expired links</Button>
          <CreateResumeForm onCreate={handleCreation} />
        </Sidebar>
      </Container>
    </>
  )
}

export default Admin
