import styled from 'styled-components'

export const FooterContainer = styled.div`
  padding-bottom: 4em;
  background: ${(props) => props.theme.colors.gray10};
  padding-top: 160px;
  padding: 48px 24px;
  @media screen and (min-width: 800px) {
    padding-left: ${(props) => props.theme.body.marginLeft};
  }
`

export const Title = styled.div`
  margin-bottom: 16px;
  ${(props) => props.theme.fonts.manrope800}
  font-size: 28px;
  @media screen and (min-width: 800px) {
    font-size: 36px;
    line-height: 36px;
  }
`

export const Subtitle = styled.div`
  margin-bottom: 40px;
  ${(props) => props.theme.fonts.manrope800}
  font-size: 18px;
  line-height: 38px;
  color: ${(props) => props.theme.colors.primary75};
  @media screen and (min-width: 800px) {
    font-size: 22px;
  }
`

export const ContactInfo = styled.div`
  ${(props) => props.theme.fonts.inter400}
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.gray100};
  @media screen and (min-width: 800px) {
    font-size: 18px;
    line-height: 32px;
  }
`
