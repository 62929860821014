import styled from 'styled-components'
import React from 'react'

const Select = styled.select`
  border: none;
  cursor: pointer;
  background-color: white;
  border: 1px solid #ededf0;
`

interface EmployeeSelectProps {
  employees: any[]
  selectedEmployeeId: string
  changedEmployee: (employeeId: string) => Promise<void>
}

export const EmployeeSelect = ({ employees, selectedEmployeeId, changedEmployee }: EmployeeSelectProps) => {
  return (
    <Select id="employeeId" value={selectedEmployeeId} onChange={(e) => changedEmployee(e.target.value)}>
      <option value="">Select an Employee</option>
      {employees.map((employee, index) => (
        <option key={index} value={employee.id}>
          {employee.lastName}, {employee.firstName}
        </option>
      ))}
    </Select>
  )
}
