import React from 'react'
import designerImg from './img/designer.png'
import developerImg from './img/developer.png'
import qaImg from './img/qa.png'
import logoSCV from './img/logo-light.png'
import { StyledLink } from '../../styles'
import {
  HeaderContainer,
  Logo,
  LogoContainer,
  Heading,
  ImgContainer,
  Photo,
  ProfileContainer,
  WorkerInfo,
  WorkerInfoWithoutPhoto,
} from './styles'
import { Employee, Roles } from '../../entities/employee'

const backgroundImage = (employee: Employee | undefined): string => {
  if (!employee?.role) {
    return ''
  }

  switch (employee.role) {
    case Roles.DESIGNER:
      return designerImg
    case Roles.DEV:
      return developerImg
    case Roles.QA:
      return qaImg
    default:
      return developerImg
  }
}

const Profile = ({ employee }: { employee: Employee | undefined }) => {
  const WorkerInfoContainer = employee?.picture ? WorkerInfo : WorkerInfoWithoutPhoto

  return (
    <ProfileContainer>
      {employee?.picture && (
        <ImgContainer>
          <Photo src={employee.picture} alt="profile" />
        </ImgContainer>
      )}
      <WorkerInfoContainer>
        <Heading>{employee?.firstName}</Heading>
        <Heading>{employee?.lastName}</Heading>
      </WorkerInfoContainer>
    </ProfileContainer>
  )
}

const Header = ({ employee }: { employee: Employee | undefined }) => (
  <HeaderContainer style={{ backgroundImage: `url(${backgroundImage(employee)})` }}>
    <LogoContainer>
      <StyledLink to="https://scvsoft.com" target="_blank" rel="noopener noreferrer">
        <Logo src={logoSCV} alt="logo" />
      </StyledLink>
    </LogoContainer>
    <Profile employee={employee}></Profile>
  </HeaderContainer>
)

export default Header
