import React from 'react'
import { FooterContainer, Title, Subtitle, ContactInfo } from './styles'

const Footer = () => (
  <FooterContainer>
    <Title>Contact us</Title>
    <Subtitle>We&apos;d love to talk</Subtitle>
    <ContactInfo>Email: info@scvsoft.com</ContactInfo>
    <ContactInfo>Phone: +1 (801) 633-1676</ContactInfo>
    <ContactInfo style={{ marginTop: 32 }}>2700 Rasmussen Road, Suite L-10, Park City, UT 84098</ContactInfo>
  </FooterContainer>
)

export default Footer
