type Skill = {
  id: number
  tag: string
}

type Resume = {
  id: number
  attributes: {
    intro: string
    summary: string
    position: string
    skills: Skill[]
  }
}

export enum Roles {
  DEV = 'developer',
  QA = 'qa',
  DESIGNER = 'designer',
}

export type Employee = {
  id: number
  firstName: string
  lastName: string
  role: Roles
  picture: string
  resume: {
    data?: Resume | null
  }
}
