import styled from 'styled-components'

export const TabContainer = styled.div`
  display: flex;
  background-color: #2d284f;
  & select {
    margin-bottom: 0.2em;
  }
`

const TabList = styled.ul`
  font-family: Manrope, sans-serif;
  display: flex;
  color: #fafafa;
  height: 30px;
  align-items: center;
  padding: 0em 2em;
  width: 20em;
  box-sizing: border-box;
  margin: 0;
`

export default TabList

export const TabItem = styled.li`
  list-style-type: none;
  border-top: solid 1px #928db1;
  border-left: solid 1px #928db1;
  border-right: solid 1px #928db1;
  border-radius: 5px 5px 0 0;
  margin-right: 1em;
  margin-bottom: 2px;
  padding: 0.3em;
  cursor: pointer;
  color: #fafafa;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  & a {
    text-decoration: none;
    color: inherit;
  }
`

export const TabItemSelected = styled(TabItem)`
  border-top: solid 3px #928db1;
  border-left: solid 3px #928db1;
  border-right: solid 3px #928db1;
  margin-bottom: 4px;
  cursor: text;
  color: #928db1;
`
