import styled from 'styled-components'
import { SectionTitle } from '../../styles'

export const DetailsContainer = styled.div`
  margin-top: -18px;
  margin-bottom: 62px;
  background: ${(props) => props.theme.colors.primary75};
`

export const Details = styled.div`
  padding: 40px 24px;
  @media screen and (min-width: 800px) {
    margin-left: ${(props) => props.theme.body.marginLeft};
    margin-right: ${(props) => props.theme.body.marginRight};
    padding-top: 60px;
    padding-bottom: 0;
  }
`

export const TagsContainer = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 30px;
  @media screen and (min-width: 800px) {
    margin-left: ${(props) => props.theme.body.marginLeft};
    margin-right: ${(props) => props.theme.body.marginRight};
    margin-top: 42px;
  }
`

export const SummaryInfo = styled.div`
  ${(props) => props.theme.fonts.inter400}
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.secondary10};
  @media screen and (min-width: 800px) {
    padding-bottom: 57px;
  }
`

export const SummaryTitle = styled(SectionTitle)`
  padding: 0 24px;
  @media screen and (min-width: 800px) {
    margin: 0 17%;
    padding: 0;
  }
`
