import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
import { Navigate, Link, useParams, useNavigate } from 'react-router-dom'
import { ReactComponent as SCVLogo } from '../../../assets/scvLogo.svg'
import TabList, { TabItem, TabItemSelected, TabContainer } from '../TabSelector'
import { EmployeeSelect } from '../EmployeeSelect'
import Header from '../../../components/Header'
import Summary from '../../../components/Summary'
import Experience from '../../../components/Experience'
import Education from '../../../components/Education'
import TechnicalSkillsContainer from '../../../components/TechnicalSkills'
import Footer from '../../../components/Footer'
import { Message } from '../../../styles.js'
import '../base.css'

const api = axios.create({ baseURL: '/api' })

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
  font-family: Inter, sans-serif;
`

const TopBar = styled.nav`
  font-family: Manrope, sans-serif;
  display: flex;
  background-color: #2d284f;
  color: #fafafa;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0em 2em;
  width: 100%;
  box-sizing: border-box;
  & header {
    display: flex;
    align-items: center;
    gap: 0.8em;
  }
  & h2 {
    font-weight: 600;
  }
`

const Logout = styled.button`
  background: transparent;
  color: #fafafa;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  cursor: pointer;
`

const MainContainer = styled.main`
  flex: 1;
`

const Preview = () => {
  const { employeeId } = useParams()
  const navigate = useNavigate()

  const [logged, setLogged] = useState<Boolean>(true)
  const [error, setError] = useState('')

  const [employees, setEmployees] = useState<any[]>([])
  const [resumeData, setResumeData] = useState<any>()

  const handleLogout = async (e: any) => {
    e.preventDefault()
    await api.post('/logout')
    setLogged(false)
  }

  const handleEmployeeChange = async (newEmployeeId: string) => {
    navigate(`/admin/preview/${newEmployeeId}`)
  }

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const { data: employees } = await api.get('/employees')
        setEmployees(employees)
        setLogged(true)
      } catch (error) {
        setLogged(false)
        console.error('Error fetching employee names:', error)
      }
    }
    getEmployees()
  }, [])

  useEffect(() => {
    const loadEmployeeResume = async () => {
      try {
        const { data: resumeData } = await api.get(`/employees/${employeeId}/resume`)
        setResumeData(resumeData)
        setError('')
      } catch (error: Error | any) {
        if ((error as AxiosError)?.response?.status === 500) {
          setError(error.response.data.message)
          setResumeData(null)
        } else {
          throw error
        }
      }
    }

    if (employeeId) loadEmployeeResume()
  }, [employeeId])

  if (!logged) {
    return <Navigate to="/login" replace={true} />
  }

  return (
    <>
      <TopBar>
        <header>
          <SCVLogo />
          <h2 style={{ margin: 0 }}>Resume builder</h2>
        </header>
        <Logout onClick={handleLogout}>Logout</Logout>
      </TopBar>
      <TabContainer>
        <TabList>
          <TabItem>
            <Link to="/admin">Shareable Links</Link>
          </TabItem>
          <TabItemSelected>Preview</TabItemSelected>
        </TabList>
        <EmployeeSelect
          employees={employees}
          selectedEmployeeId={employeeId || ''}
          changedEmployee={handleEmployeeChange}
        />
      </TabContainer>
      {error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <MainContainer>
            {resumeData ? (
              <>
                <Header employee={resumeData.employee} />
                <Summary resume={resumeData.resume} />
                <Experience projects={resumeData.projects} />
                <TechnicalSkillsContainer items={resumeData.resume} />
                <Education items={resumeData.education} />
                <Footer />
              </>
            ) : (
              <></>
            )}
          </MainContainer>
        </Container>
      )}
    </>
  )
}

export default Preview
