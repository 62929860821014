import styled from 'styled-components'

export const ExperienceItemContainer = styled.div`
  margin-bottom: 60px;
`

export const TechIconsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
`

export const ItemSCV = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 30px;
  background-color: ${(props) => props.theme.colors.gray10};
  ${(props) => props.theme.fonts.manrope800}
  padding: 8px 16px;
  color: ${(props) => props.theme.colors.primary75};
  max-width: 175px;
  border-radius: 51px;
  text-align: center;
  @media screen and (min-width: 800px) {
    font-size: 18px;
    max-width: 100%;
  }
`

export const BuildAtSpan = styled.span`
  color: ${(props) => props.theme.colors.gray50};
  margin-right: 5px;
`

export const TechIcon = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  box-shadow: 0px 2.22243px 5.55608px rgba(26, 99, 143, 0.2);
  width: 60px;
  height: 60px;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    filter: invert(43%) sepia(29%) saturate(7071%) hue-rotate(143deg) brightness(82%) contrast(70%);
    transform: translate(-50%, -50%);
  }
`

export const ItemHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`
