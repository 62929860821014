import styled from 'styled-components'

export const TimelineContainer = styled.div`
  ${(props) => props.theme.fonts.manrope800}
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  box-shadow: 0px 2px 5px rgba(26, 99, 143, 0.2);
`

export const TimelineItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  align-items: center;
  opacity: 1;
  flex-wrap: no-wrap;
  scroll-behavior: smooth;
  ${(props) => props.theme.fonts.manrope800}
`

export const Item = styled.div`
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: ${(props) => props.theme.colors.secondary25};
  opacity: 0.4;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
`
export const ItemActive = styled(Item)`
  display: block;
  border-bottom: 3px solid rgb(1, 166, 125);
  box-shadow: 0px 2px 8px rgba(232, 239, 244, 0.5);
  background: ${(props) => props.theme.colors.gray10};
  opacity: 1;
  color: ${(props) => props.theme.colors.secondary50};
  padding-left: 25px;
  padding-right: 25px;
`

export const Button = styled.button`
  background-color: transparent;
  border: none;
  margin: 0 1rem;
`
