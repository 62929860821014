import React from 'react'
import { StyledLink } from '../../styles'
import styled from 'styled-components'
import developerImg from './img/developer.png'
import logoSCV from './img/logo-light.png'

export const HeaderContainer = styled.div`
  background: top center no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;

  @media (max-width: 800px) {
    .flex-container {
      flex-direction: column;
    }
  }
`

export const LogoContainer = styled.div`
  display: flex;
  margin-left: ${(props) => props.theme.body.marginLeft};
  width: 100%;
  padding-top: 2rem;
`
export const Logo = styled.img`
  width: 100%;
  height: 100%;
`

const Home = () => {
  return (
    <>
      <HeaderContainer style={{ backgroundImage: `url(${developerImg})` }}>
        <LogoContainer>
          <StyledLink to="https://scvsoft.com" target="_blank" rel="noopener noreferrer">
            <Logo src={logoSCV} alt="logo" />
          </StyledLink>
        </LogoContainer>
      </HeaderContainer>
    </>
  )
}

export default Home
