import React, { useState } from 'react'
import { SectionTitle, ItemSubtitle, ItemDescription, ItemTitle, LogoSCV, ItemSeparator, Container } from '../../styles'
import { ExperienceItemContainer, TechIconsContainer, TechIcon, ItemHeader, BuildAtSpan, ItemSCV } from './styles'
import Timeline from '../Timeline'
import { Project } from '../../entities/project'
import { Technology } from '../../entities/technology'
import logoSCV from './img/scv_icon.svg'

const SCVProjectBadge = () => {
  return (
    <ItemSCV>
      <BuildAtSpan>Built at</BuildAtSpan>
      <LogoSCV src={logoSCV} />
      SCV Soft
    </ItemSCV>
  )
}

const ItemTechnologies = ({ technologies }: { technologies?: Technology[] | null }) => {
  return (
    <TechIconsContainer>
      {technologies?.map(({ id, name, iconUrl }) => (
        <TechIcon key={`tech-icon-${id}`}>
          <img src={iconUrl} alt={name} title={name} />
        </TechIcon>
      ))}
    </TechIconsContainer>
  )
}

const ExperienceItem = ({
  project,
  role,
  description,
  roleSummary,
  technologies,
  isSCVProject,
}: {
  project: string
  role: string
  description: string
  roleSummary: string
  isSCVProject: boolean
  technologies?: Technology[] | null
}) => {
  return (
    <ExperienceItemContainer>
      <ItemHeader>
        <div>
          <ItemTitle>{role}</ItemTitle>
          <ItemSubtitle>{project}</ItemSubtitle>
        </div>
        {isSCVProject && <SCVProjectBadge />}
      </ItemHeader>
      <ItemDescription dangerouslySetInnerHTML={{ __html: description }}></ItemDescription>
      <ItemDescription dangerouslySetInnerHTML={{ __html: roleSummary }}></ItemDescription>
      <ItemTechnologies technologies={technologies} />
    </ExperienceItemContainer>
  )
}

const Experience = ({ projects }: { projects: Project[] }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const activeProject = projects[activeItemIndex]

  const handleExperienceSelected = (itemIndex: number) => {
    setActiveItemIndex(itemIndex)
  }

  const renderDisplayLabel = (project: Project) => {
    const { startYear, endYear } = project
    if (!endYear) {
      return `Since ${startYear.toString()}`
    }

    if (startYear === endYear) {
      return startYear.toString()
    }

    return `${endYear.toString()}-${startYear?.toString()}`
  }

  return (
    <Container>
      <SectionTitle>Experience</SectionTitle>
      <Timeline
        projects={projects}
        onTimelineItemClick={handleExperienceSelected}
        activeItemIndex={activeItemIndex}
        renderDisplayLabel={renderDisplayLabel}
      />
      <div style={{ paddingBottom: '55px' }} />

      <ExperienceItem
        isSCVProject={activeProject.isSCVProject}
        project={activeProject.project}
        role={activeProject.role || ''}
        description={activeProject.description || ''}
        roleSummary={activeProject.roleSummary || ''}
        technologies={activeProject.technologies}
        key={`experience-item-${activeItemIndex}`}
      />
      <ItemSeparator />
    </Container>
  )
}

export default Experience
