import React from 'react'
import { SectionTitle, ItemTitle, ItemSubtitle, ItemDescription } from '../../styles'
import { EducationContainer, TitleContainer, ItemDate, ItemStatus, EducationSeparator } from './styles'

type EducationItem = {
  id: number
  startYear: string
  endYear: string
  title: string
  institution: string
  description?: string
  abandoned: boolean
  status: string
}

const formattedDateRange = (startYear: string, endYear?: string | null, abandoned?: boolean | null) => {
  const finishYear = endYear ? endYear : ''
  const status = abandoned ? ' (Incomplete)' : ''

  if (startYear && !finishYear) {
    return startYear
  }

  return `${startYear} - ${finishYear}${status}`
}

const setItemDateStyle = (status: string) => {
  let styleObj = {}
  switch (status) {
    case 'abandoned':
      styleObj = {
        color: '#66687B',
      }
      break
    case 'ongoing':
      styleObj = {
        color: '#804BD7',
      }
      break
    case 'completed':
      styleObj = {
        display: 'none',
      }
      break
  }

  return styleObj
}

const getStatusLabel = (status: string) => {
  switch (status) {
    case 'abandoned':
      return 'incomplete'
    case 'ongoing':
      return 'in progress'
    default:
      return status
  }
}

const Education = ({ items }: { items: EducationItem[] }) => (
  <EducationContainer>
    <SectionTitle>Education</SectionTitle>
    {items.map((i, index) => {
      return (
        <>
          <div data-testid={`education-item-${index}`} key={`education-item-${index}`}>
            <TitleContainer>
              <ItemTitle>{i.title}</ItemTitle>
              <div style={{ display: 'flex' }}>
                {i.startYear && <ItemDate>{formattedDateRange(i.startYear, i.endYear, i.abandoned)}</ItemDate>}
                <ItemStatus style={setItemDateStyle(i.status)}>{getStatusLabel(i.status)}</ItemStatus>
              </div>
            </TitleContainer>
            <ItemSubtitle>{i.institution}</ItemSubtitle>
            <ItemDescription dangerouslySetInnerHTML={{ __html: i.description ?? '' }}></ItemDescription>
            <EducationSeparator />
          </div>
        </>
      )
    })}
  </EducationContainer>
)

export default Education
