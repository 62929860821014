import React from 'react'
import Tag from '../Tag'
import { DetailsContainer, Details, TagsContainer, SummaryInfo, SummaryTitle } from './styles'

const Summary = ({ resume }: { resume: any }) => {
  return (
    <>
      <SummaryTitle>{resume?.position}</SummaryTitle>
      <TagsContainer>
        {resume.skills.map((tag: string, index: number) => (
          <Tag label={tag} key={`summary-tag-${tag}`} />
        ))}
      </TagsContainer>
      <DetailsContainer>
        <Details>
          <SummaryInfo dangerouslySetInnerHTML={{ __html: resume.summary }}></SummaryInfo>
        </Details>
      </DetailsContainer>
    </>
  )
}

export default Summary
