import React from 'react'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
`
const Th = styled.th`
  background-color: #f2f2f2;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: Manrope, sans-serif;
`

const Td = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: Inter, sans-serif;
`

const ActionIcon = styled.a`
  color: #666;
  background-color: #fff;
  cursor: pointer;
  padding: 2px 12px 2px 0px;
  font-size: 18px;
`
const DeleteIcon = styled(ActionIcon)`
  color: #f00;
`

const deleteHandler = (resume: any, onDelete: (resume: any) => Promise<void>) => (e: any) => {
  e.preventDefault()
  if (
    window.confirm(
      `are you sure you want to delete link of ${resume.firstName} ` +
        `${resume.lastName} with purpose "${resume.purpose}"`,
    )
  ) {
    onDelete(resume)
  }
}

const copyHanlder = (resume: any) => (e: any) => {
  e.preventDefault()
  navigator.clipboard.writeText(resume.url)
  toast.success('Link copied to clipboard')
}

const ResumesTable = ({ resumes, onDelete }: { resumes: any[]; onDelete: (resume: any) => Promise<void> }) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <Th>ID</Th>
            <Th>Employee ID</Th>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Purpose</Th>
            <Th>Expires At</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {resumes.map((resume: any) => {
            return (
              <tr key={resume.id}>
                <Td>{resume.id}</Td>
                <Td>{resume.employeeId}</Td>
                <Td>{resume.firstName}</Td>
                <Td>{resume.lastName}</Td>
                <Td>{resume.purpose}</Td>
                <Td>
                  {new Date(resume.expiresAt).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Td>
                <Td>
                  <ActionIcon href={resume.url} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faEye} />
                  </ActionIcon>
                  <ActionIcon href="#" onClick={copyHanlder(resume)}>
                    <FontAwesomeIcon icon={faCopy} />
                  </ActionIcon>
                  <DeleteIcon href="#" onClick={deleteHandler(resume, onDelete)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </DeleteIcon>
                </Td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default ResumesTable
