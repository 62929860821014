import styled from 'styled-components'

export const Item = styled.div`
  padding: 6px 10px;
  gap: 10px;
  border-radius: 5px;
  margin-right: 16px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray100};
  background: ${(props) => props.theme.colors.secondary25};
  @media screen and (min-width: 800px) {
    font-size: 22px;
    line-height: 22px;
    margin-right: 16px;
  }

  ${(props) => props.color && `background: ${props.color}`}
`
