import Resume from './pages/Resume'
import Home from './pages/Home'
import Login from './pages/Login'
import Admin from './pages/Admin'
import Preview from './pages/Admin/Preview'

const routes = [
  {
    path: 'resumes/:key',
    element: <Resume />,
  },
  {
    path: '*',
    element: <Home message="Please specify a valid link to access a resume" />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'admin',
    element: <Admin />,
  },
  {
    path: 'admin/preview/:employeeId?',
    element: <Preview />,
  },
]

export default routes
