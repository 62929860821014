import styled from 'styled-components'
import React, { useState } from 'react'
import axios from 'axios'
import Home from '../Home'
import { ReactComponent as SCVLogo } from '../../assets/scvLogo.svg'

const api = axios.create()
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoginWithSCV = styled.button`
  cursor: pointer;
  font-weight: 600;
  font-family: Inter, sans-serif;
  border-radius: 8px;
  background: #fff;
  color: #373a50;
  box-shadow:
    0px 2px 3px 0px rgba(0, 0, 0, 0.17),
    0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 1em 4em;
  transition: 0.4s all;

  & svg {
    transition: inherit;
    fill: #01a67d;
  }

  &:hover {
    background-color: #01a67d;
    color: white;
  }

  &:hover svg {
    fill: #ffffff;
  }
`

const Login = () => {
  const [error, setError] = useState<string>('')

  const handleLoginButton = async () => {
    try {
      const { data } = await api.get('/auth/login/scv')
      window.location.replace(data.loginUrl)
    } catch (e: any) {
      setError(e.response.data?.message ?? 'Unknown error, please check network logs')
    }
  }

  if (error) {
    return <Home message={error} />
  }

  return (
    <Container>
      <LoginWithSCV data-action="google-login" onClick={handleLoginButton}>
        <SCVLogo />
        Continue with SCV
      </LoginWithSCV>
    </Container>
  )
}

export default Login
