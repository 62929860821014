import React from 'react'

import SquareDotIcon from '../Icons/Dot-square-icon'
import { SkillItem } from './styles'
import { ItemSeparator, SectionTitle, Container } from '../../styles'

type TechnicalSkillsItems = {
  qualifications: string[]
}

const TechnicalSkills = ({ items }: any) => {
  return (
    <Container>
      <SectionTitle>Summary of Qualifications</SectionTitle>
      {items.qualifications.map((item: string) => (
        <SkillItem>
          <SquareDotIcon fill="#006F51)" />
          {item}
        </SkillItem>
      ))}
      <ItemSeparator />
    </Container>
  )
}

export default TechnicalSkills
