import React, { useRef } from 'react'
import { Item, ItemActive, TimelineContainer, TimelineItemContainer, Button } from './styles'
import ArrowNextIcon from '../Icons/arrow-next'
import ArrowPreviousIcon from '../Icons/arrow-previous'
import { Project } from '../../entities/project'

const TimelineItem = ({
  timelineDate,
  onClick,
  selectedItemIndex,
}: {
  timelineDate: string
  onClick: (selectedItemId: number) => void
  selectedItemIndex: number
}) => <Item onClick={() => onClick(selectedItemIndex)}>{timelineDate}</Item>

const Timeline = ({
  projects,
  onTimelineItemClick,
  activeItemIndex,
  renderDisplayLabel,
}: {
  projects: Project[]
  onTimelineItemClick: (selectedItemIndex: number) => void
  activeItemIndex: number
  renderDisplayLabel: (project: Project) => string
}) => {
  const ref = useRef<null | any>(null)

  const handleHorizontalScroll = (number: number) => {
    ref.current.scrollLeft += number
  }

  return (
    <TimelineContainer>
      {ref.current && ref.current.scrollWidth > ref.current.clientWidth && (
        <Button onClick={() => handleHorizontalScroll(-100)}>
          <ArrowPreviousIcon />
        </Button>
      )}

      <TimelineItemContainer ref={ref}>
        {projects.map((project, index) =>
          index === activeItemIndex ? (
            <ItemActive key={`timeline-item-${index}`}>{renderDisplayLabel(project)}</ItemActive>
          ) : (
            <TimelineItem
              timelineDate={renderDisplayLabel(project)}
              key={`timeline-item-${index}`}
              onClick={() => onTimelineItemClick(index)}
              selectedItemIndex={index}
            />
          ),
        )}
      </TimelineItemContainer>
      {ref.current && ref.current.scrollWidth > ref.current.clientWidth && (
        <Button onClick={() => handleHorizontalScroll(100)}>
          <ArrowNextIcon />
        </Button>
      )}
    </TimelineContainer>
  )
}

export default Timeline
