import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import routes from './routes'
import { ThemeProvider } from 'styled-components'
import { theme, App } from './styles'
import reportWebVitals from './reportWebVitals'
import './globals.css'

const router = createBrowserRouter(routes)
const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App>
        <RouterProvider router={router} fallbackElement={<p>Loading</p>} />
      </App>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
